<template>
  <div class="content">
    <PageHeader title="WhatsApp" :subtitle="$t('template-component.new')" />
    <section class="page-content container-fluid mb-3">
      <div class="row">
        <!-- Content col -->
        <div class="col-8 left-content-column">
          <h2>Categoria</h2>
          <span>Escolha uma categoria que melhor descreva seu modelo de mensagem</span>
          <div class="accordion accordion-wpp-template" role="tablist">
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <!-- <b-button block v-b-toggle.accordion-1 variant="info">Accordion 1</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="toggleAccordion('accordion-1'), changeCategory('MARKETING')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined">campaign</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Marketing</p>
                      <span>Promoções ou informações sobre sua empresa, produtos ou serviços. Ou qualquer mensagem que não seja relevante ou de autenticação.</span>
                    </div>
                  </button>
                  <div class="control-group opt-2 toggle-check">
                    <label class="control control-checkbox">
                      <input id="accordion-1-input" class="wpp-input-opt" type="radio" name="wpp-opt-radio" onclick="return false" checked>
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </b-card-header>
              <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                <!-- <b-card-body>
                  <b-card-text>
                    <ul class="list-unstyled">
                      <li>
                        <div class="control-group opt-3">
                          <label class="control control-checkbox">
                            <input type="radio" />
                            <div class="control_indicator control-rounded"></div>
                            <span>Recente (Padrão)</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="control-group opt-3">
                          <label class="control control-checkbox">
                            <input type="radio" />
                            <div class="control_indicator control-rounded"></div>
                            <span>Recente (Padrão)</span>
                          </label>
                        </div>
                      </li>
                      <li>
                        <div class="control-group opt-3">
                          <label class="control control-checkbox">
                            <input type="radio" />
                            <div class="control_indicator control-rounded"></div>
                            <span>Recente (Padrão)</span>
                          </label>
                        </div>
                      </li>
                    </ul>
                  </b-card-text>
                </b-card-body> -->
              </b-collapse>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <!-- <b-button block v-b-toggle.accordion-2 variant="info">Accordion 2</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="toggleAccordion('accordion-2'), changeCategory('UTILITY')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined">notifications</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Utilidade</p>
                      <span>Envie mensagens sobre uma conta ou pedido existente.</span>
                    </div>
                  </button>
                  <div class="control-group opt-2 toggle-check">
                    <label class="control control-checkbox">
                      <input id="accordion-2-input" class="wpp-input-opt" type="radio" name="wpp-opt-radio" onclick="return false">
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </b-card-header>
              <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
                <!-- <b-card-body>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body> -->
              </b-collapse>
            </b-card>

            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <!-- <b-button block v-b-toggle.accordion-3 variant="info">Accordion 3</b-button> -->
                <div class="btn-wrapper-opt">
                  <button @click="toggleAccordion('accordion-3'), changeCategory('AUTHENTICATION')" class="btn btn-template stretched-link">
                    <span class="material-symbols-outlined fill-icon">key</span>
                    <div class="wrapper-title">
                      <p class="title-opt">Autenticação</p>
                      <span>Senhas descartáveis que seus clientes usam para autenticar uma transação ou login.</span>
                    </div>
                  </button>
                  <div class="control-group opt-2 toggle-check">
                    <label class="control control-checkbox">
                      <input id="accordion-3-input" class="wpp-input-opt" type="radio" name="wpp-opt-radio" onclick="return false">
                      <div class="control_indicator"></div>
                    </label>
                  </div>
                </div>
              </b-card-header>
              <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
                <!-- <b-card-body>
                  <b-card-text>{{ text }}</b-card-text>
                </b-card-body> -->
              </b-collapse>
            </b-card>
          </div>
          <div class="form-group mb-4">
            <label for="wpp-language">Telefone</label>
            <select class="form-control" @change="changeNumber" v-model="whatsapp_identification">
              <option v-for="number in whatsNumbers" :key="number.id" :value="number.phone_number">
                {{ number.phone_number | phone }} ({{ number.name }})
              </option>
            </select>
          </div>
          <h2 class="mb-4">Informações da Campanha</h2>
          <div class="form-group mb-4">
            <label for="wpp-name">{{$tc('app.name', 1)}}:</label>
            <input id="wpp-name" class="form-control" :placeholder="$t('template-component.give-name')" v-model="template.structure.name" @input="fixTitleChars()" maxlength="512">
            <small class="form-text text-muted">{{ template.structure.name.length }}/512</small>
          </div>
          <!-- Header -->
          <label class="mb-4">Header:</label>
          <div class="header-wrapper">
            <b-card no-body>
              <b-card-header header-tag="header" :class="{'active':template.headerType == 'TEXT',}">
                <img src="@/assets/skeleton-text.svg" alt="Skeleton Text" />
                <button class="btn btn-choose-type stretched-link" @click="template.headerType = 'TEXT'">Texto
                </button>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" :class="{'active':template.headerType == 'IMAGE',}">
                <div class="icon-wrapper">
                  <span class="material-symbols-outlined">photo</span>
                </div>
                <button class="btn btn-choose-type stretched-link" @click="chooseImage(500, 280), template.headerType = 'IMAGE'">Imagem</button>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" :class="{'active':template.headerType == 'VIDEO',}">
                <div class="icon-wrapper">
                  <span class="material-symbols-outlined">video_library</span>
                </div>
                <div class="sr-only" v-if="template.headerType == 'VIDEO'">
                  <input type="file" ref="currentFile" accept="video/mp4" @change="getLocalFile('VIDEO')" />
                </div>
                <button class="btn btn-choose-type stretched-link" @click="chooseFile(), template.headerType = 'VIDEO'">Vídeo</button>
              </b-card-header>
            </b-card>
            <b-card no-body>
              <b-card-header header-tag="header" :class="{'active':template.headerType == 'DOCUMENT',}">
                <div class="icon-wrapper">
                  <span class="material-symbols-outlined">description</span>
                </div>
                <div class="sr-only" v-if="template.headerType == 'DOCUMENT'">
                  <input type="file" ref="currentFile" accept="application/pdf" @change="getLocalFile('DOCUMENT')" />
                </div>
                <button class="btn btn-choose-type stretched-link" @click="chooseFile(), template.headerType = 'DOCUMENT'">Documento</button>
              </b-card-header>
            </b-card>
          </div>
          <div class="form-group mb-4" v-if="template.headerType == 'TEXT'">
            <label for="wpp-name">Texto do header:</label>
            <input id="wpp-name" class="form-control" v-model="template.headers.TEXT.body" @input="updateHeaderParams()" maxlength="60">
            <small class="form-text text-muted">{{ template.headers.TEXT.body.length }}/60</small>
          </div>
          <!--/Header -->
          <!-- Body -->
          <div class="form-group mb-4">
            <label>Body: </label>
            <whats-input v-model="template.structure.body.text" :variables="vars" :paramsChange="updateBodyParams"/>
            <small class="form-text text-muted">{{ template.structure.body.text.length }}/1024</small>
            <div class="alert alert-danger" role="alert" v-if="!validBodyParams">
              <h4 class="alert-heading">Problemas nas variáveis</h4>
              <p>Existem problemas nas variáveis, recomendamos que
                <a class="text-light" target="_blank" rel="noopener noreferrer" href="https://developers.facebook.com/docs/whatsapp/message-templates/guidelines/?locale=pt_BR">
                  leia mais sobre formatação em diretrizes de modelo de
                  mensagem
                </a>
              </p>
            </div>
            <template v-else>
              <div class="row" v-if="template.structure.body.text.length">
                <button @click="deleteParam" class="btn btn-primary ml-auto mr-3">
                  Limpar parametros
                </button>
              </div>
              <template v-for="(param, index) in template.structure.body.example_parameters">
                <p class="param-col" :key="index">
                  <label>Parâmetro {{ index + 1 }} de exemplo</label>
                  <input type="text" class="form-control" v-model="template.structure.body.example_parameters[index]"/>
                </p>
              </template>
            </template>
          </div>
          <!--/Body -->
          <!-- Footer -->
          <div class="form-group mb-4">
            <label for="wpp-footer">Footer <span>(Opcional)</span></label>
            <input id="wpp-footer" class="form-control" placeholder="Parte inferior do seu modelo de mensagem." v-model="template.footer.body" maxlength="512">
            <small class="form-text text-muted">{{ template.footer.body.length }}/512</small>
          </div>
          <div class="form-group mb-4">
            <label for="wpp-btn">Buttons <span>(Opcional)</span></label>
            <span class="d-block w-100 mb-1">Crie até 3 botões que permitem que os clientes respondam à sua mensagem ou atuem.</span>
            <select class="form-control" v-model="template.buttons.type">
              <option value="">Nenhum</option>
              <option value="QUICK_REPLY">Respostas rápidas</option>
              <option value="CALL_TO_ACTION">Chamada (Call To Action)</option>
            </select>
            <!-- Quick reply -->
            <template v-if="template.buttons.type === 'QUICK_REPLY'">
              <template v-for="(_, index) in template.quick_replies">
                <p :key="index">
                  <label>
                    Resposta rápida {{ index + 1 }}
                    <span class="badge badge-success" v-if="index === 0">Obrigatório</span>
                    <span class="badge badge-optional" v-else>Opcional</span>
                  </label>
                  <input type="text" class="form-control" maxlength="25" v-model="template.quick_replies[index]"/>
                </p>
              </template>
              <div class="alert alert-danger" role="alert" v-if="!validQuickReplies">
                <h4 class="alert-heading">Problemas nas respostas rápidas</h4>
                <p>Você não pode inserir o mesmo texto para diferentes botões</p>
              </div>
            </template>
            <!--/Quick reply -->
            <!-- Call to action -->
            <template v-if="template.buttons.type === 'CALL_TO_ACTION'">
              <p>Preencha telefone ou website ou ambos</p>
              <div class="card card-btn-action">
                <div class="card-header">Chamar telefone</div>
                <div class="row mb-3 card-body">
                  <div class="col-md-6">
                    <label>Texto do botão</label>
                    <input type="text" class="form-control" maxlength="25" v-model="template.call_to_action_dial_phone.text"/>
                  </div>
                  <div class="col-md-6">
                    <label>Telefone</label>
                    <vue-tel-input
                      v-bind="dialPhoneProps"
                      v-model="template.call_to_action_dial_phone.number"
                    />
                  </div>
                </div>
              </div>
              <div class="card card-btn-action">
                <div class="card-header">Visitar website</div>
                <div class="row card-body pb-0 mb-0">
                  <div class="col-md-6">
                    <label>Texto do botão</label>
                    <input type="text" class="form-control" maxlength="25" v-model="template.call_to_action_website.text"/>
                  </div>
                  <div class="col-md-6">
                    <label>Website</label>
                    <input type="text" class="form-control" maxlength="2000" v-model="template.call_to_action_website.url"/>
                  </div>
                  <div class="col-12">
                    <div class="input-group my-4" v-if="hasLinkParam">
                      <div class="input-group-prepend">
                        <span class="input-group-text">Exemplo de parametro</span>
                      </div>
                      <input type="text" class="form-control" placeholder="Digite um exemplo para variável..." v-model="template.call_to_action_website.example"/>
                    </div>
                  </div>
                </div>
                <p class="mx-4 my-2">
                  Apenas uma variável
                  <code v-html="'{{1}}'"></code> pode ser adicionada ao
                  final de uma URL (<a class="text-light" target="_blank" rel="noopener noreferrer" href="https://business.facebook.com/business/help/2055875911147364">Leia mais</a>), exemplos:
                </p>

                <div class="alert alert-primary mx-4 my-2" role="alert">
                  <pre class="mb-0"><code>https://meusite/?id=<span v-html="'{{1}}'"></span></code></pre>
                </div>

                <div class="alert alert-primary mx-4 my-2" role="alert">
                  <pre class="mb-0"><code>https://meusite/promocao/<span v-html="'{{1}}'"></span></code></pre>
                </div>
                <p></p>
              </div>
            </template>
            <!--/Call to action -->
            <!-- <div class="m-t-10" v-if="template.button_type === 'quick_reply'">
              <button class="btn btn-info" @click="addQuickReply" :class="{ disabled: template.buttons_reply.length == 3, }">
                <i class="fas fa-plus"></i>
              </button>
            </div>
            <div class="m-t-10" v-if="template.button_type === 'call_to_action'">
              <button class="btn btn-info" @click="addCallToButton" :class="{ disabled: template.call_to_actions.length == 2, }">
                <i class="fas fa-plus"></i>
              </button>
            </div> -->
          </div>
          <!--/Footer -->
          <div class="btn-wrapper">
            <button class="btn btn-outline-primary" @click="$router.go(-1)">{{$t('app.cancel')}}</button>
            <button @click="saveTemplate()" class="btn btn-yup-purple ml-auto"
              :disabled="isSending || !validTemplate" :class="{'qt-loader qt-loader-mini qt-loader-right': isSending,}">
              {{$t('app.save')}} template
            </button>
          </div>
        </div>
        <!--/Content col -->
        <!-- Preview -->
        <div class="col">
          <div class="preview-wrapper">
            <div class="card card-preview">
              <div class="card-body">
                <header class="card-text" v-if="template.headerType === 'TEXT'">
                  <b>{{ template.headers.TEXT.body }}</b>
                </header>
                <header v-else-if="template.headerType === 'IMAGE'">
                  <div class="media">
                    <img :src="template.headerImage.url" v-if="template.headerImage" />
                  </div>
                </header>
                <header v-else-if="template.headerType === 'VIDEO'">
                  <div class="media">
                    <video :src="template.headerVideo.url" v-if="template.headerVideo" autoplay></video>
                  </div>
                </header>
                <header v-else-if="template.headerType === 'DOCUMENT'">
                  <div class="media">
                    <object type="application/pdf" :data="template.headerDocument.url" v-if="template.headerDocument"></object>
                  </div>
                </header>
                <div class="card-text">{{ template.structure.body.text }}</div>
                <footer class="card-text footer-item" v-if="template.footer.body">{{ template.footer.body }}</footer>
              </div>
            </div>
            <div class="card card-preview" v-if="template.buttons.type === 'QUICK_REPLY' || template.buttons.type === 'CALL_TO_ACTION'">
              <div class="card-body">
                <div class="preview-btns">
                  <template v-if="template.buttons.type === 'QUICK_REPLY'">
                    <template v-for="(reply, i) in template.quick_replies.filter((a) => !!a)">
                      <div class="btn" :key="i" :class="{ last: i === 2 }">{{ reply }}</div>
                    </template>
                  </template>
                  <template v-if="template.buttons.type === 'CALL_TO_ACTION'">
                    <div class="btn last" v-if="template.call_to_action_dial_phone.text">
                      <i class="fas fa-phone"></i>
                      {{ template.call_to_action_dial_phone.text }}
                    </div>
                    <div class="btn last" v-if="template.call_to_action_website.text">
                      <i class="fas fa-link"></i>
                      {{ template.call_to_action_website.text }}
                    </div>
                  </template>
                </div>
              </div>
            </div>
            <img class="phone-img" src="@/assets/phone.svg" alt="Phone Background" />
          </div>
        </div>
        <!--/Preview -->
      </div>
    </section>
    <create-template-modal id="create-template-modal" />
    <send-template-modal id="send-template-modal" />
    <preview-modal id="preview-modal" />
    <m-crop id="m-crop" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import CreateTemplateModal from '@/components/whatsapp/CreateTemplateModal.vue';
import SendTemplateModal from '@/components/whatsapp/SendTemplateModal.vue';
import PreviewModal from '@/components/whatsapp/PreviewModal.vue';
import WhatsInput from '@/components/rebranding/whatsapp/WhatsInput.vue';
import WhatsAppService from '@/services/whatsapp.service';
import MCrop from '@/components/MCrop.vue';
import BucketService from '@/services/bucket.service';
import axios from '@/plugins/axios';
import { VueTelInput } from 'vue-tel-input';

const webApi = axios();

export default {
  name: 'WhatsAppTemplates',
  components: {
    PageHeader,
    CreateTemplateModal,
    PreviewModal,
    SendTemplateModal,
    WhatsInput,
    MCrop,
    VueTelInput,
  },
  watch: {
    template: {
      handler(newValue, oldValue) {
        setTimeout(() => this.updateBodyParams(), 100);
      },
      deep: true,
    },
  },
  computed: {
    validTemplate() {
      if (!this.validBody) {
        console.warn('Template: Invalid body');
        return false;
      }

      const template = this.template;

      if (!/^[A-Za-z0-9_]+$/.test(template.structure.name)) {
        console.warn(
          'Template: Invalid template name',
          template.structure.name,
        );
        return false;
      }

      if (this.headerType === 'TEXT') {
        if (!this.validHeader) {
          console.warn('Template: Invalid header (TEXT)');
          return false;
        }
      }

      const btnType = template.buttons.type;

      if (btnType === 'QUICK_REPLY') {
        if (!this.validQuickReplies) {
          console.warn('Template: Invalid quick_replies');
          return false;
        }
      } else if (btnType === 'CALL_TO_ACTION') {
        const dial = template.call_to_action_dial_phone;
        const web = template.call_to_action_website;

        // 5513912345678
        if (dial.number || dial.text) {
          if (
            !dial.number ||
            !dial.text ||
            !/^\d{8,}$/.test(dial.number.replace(/\D+/g, ''))
          ) {
            console.warn('Template: Invalid call_to_action_dial_phone', dial);
            return false;
          }
        }

        // https://foo.bar/bar
        if (web.number || web.text) {
          if (!web.url || !web.text || !/https?:\/\/\S{5,}$/.test(web.url)) {
            console.warn('Template: Invalid call_to_action_website', web);
            return false;
          }
        }
      }

      return true;
    },
    validHeader() {
      if (this.headerType === 'TEXT') {
        const header = this.template.headers.TEXT.body;

        if (header.length > 60) return false;

        const params = [...header.matchAll(/\{\{([^\s]+?)\}\}/g)];

        if (params.length === 0) return true;

        return params.length > 1 || params[0][1] !== '{{1}}';
      }

      return true;
    },
    hasHeaderParam() {
      return /\{\{([^\s]+?)\}\}/.test(this.template.headers.TEXT.body);
    },
    headerParamMaxLength() {
      const header = this.template.headers.TEXT.body.replace(/\{\{1\}\}/, '');
      const param = this.template.headers.TEXT;

      const max = 60 - header.length;

      if (param.example_parameter) {
        param.example_parameter = param.example_parameter.substring(0, max);
      }

      return max;
    },

    validBodyParams() {
      const body = this.template.structure.body.text.trim();

      const begin = /([.!?{}]+)?\{\{[^\s]+?\}\}(\s+)$/;
      const ends = /\{\{[^\s]+?\}\}(\s+)?([.!?]+)?$/;
      const sequence = /\{\{[^\s]+?\}\}(\s+?)\{\{[^\s]+?\}\}/;

      if (begin.test(body) || ends.test(body) || sequence.test(body)) {
        return false;
      }

      const params = [...body.matchAll(/\{\{([^\s]+?)\}\}/g)];

      for (let i = 0, j = params.length; i < j; i += 1) {
        const param = parseInt(params[i][1], 10);
        if (!Number.isInteger(param)) return false;
      }

      return true;
    },
    validBody() {
      const body = this.template.structure.body.text;

      // console.log('validBody', body, body.length);

      return body.length <= 1028 && body.trim() !== '' && this.validBodyParams;
    },

    hasLinkParam() {
      return (
        [
          ...this.template.call_to_action_website.url.matchAll(
            /\{\{([^\s]+?)\}\}/g,
          ),
        ].length === 1
      );
    },

    validQuickReplies() {
      const replies = this.template.quick_replies;

      return !replies.some(
        (reply, index) => reply && replies.indexOf(reply) !== index,
      );
    },
  },
  data() {
    return {
      business_identification: '',
      whatsNumbers: [],
      uploadProgress: 0,
      form: {},
      isSending: false,
      fetched: false,
      client: {},
      defaultBg: '/assets/img/image_preview_white.png',
      defaultBgPdf: '/assets/img/image_preview_white.png',
      cropActive: null,
      cropPreview: null,
      hidePreview: false,
      vars: [
        { name: this.$t('generic-str.lbl-first-name'), value: 'first_name' },
        { name: this.$t('generic-str.lbl-last-name'), value: 'last_name' },
        { name: this.$t('generic-str.lbl-cellphone'), value: 'mobile_number' },
        { name: 'Email', value: 'email' },
      ],
      file: null,
      testTemplate: null,
      template: {
        structure: {
          business_id: '',
          category: 'MARKETING',
          name: '',
          language: 'pt_BR',
          // identification: '',
          header: null,
          body: {
            example_parameters: [],
            text: '',
          },
          footer: null,
          buttons: null,
        },
        headerType: '',
        headers: {
          TEXT: {
            body: '',
            example_parameter: null,
            type: 'TEXT',
          },
          IMAGE: {
            body: '',
            example_parameter: null,
            type: 'IMAGE',
          },
          VIDEO: {
            body: '',
            example_parameter: null,
            type: 'VIDEO',
          },
          DOCUMENT: {
            body: '',
            example_parameter: null,
            type: 'DOCUMENT',
          },
        },
        headerImage: null,
        headerVideo: null,
        headerDocument: null,
        footer: {
          body: '',
        },
        buttons: {
          type: '',
        },
        call_to_action_dial_phone: {
          number: '',
          text: '',
        },
        call_to_action_website: {
          example: '',
          text: '',
          url: '',
        },
        quick_replies: ['', '', ''],
      },
      lastBody: null,
      dialPhoneProps: {
        mode: 'international',
        defaultCountry: 'BR',
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: true,
        placeholder: this.$t('generic-str.lbl-cellphone'),
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        validCharactersOnly: true,
        preferredCountries: ['AU', 'BR'],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'off',
        name: 'telephone',
        maxLen: 25,
        wrapperClasses: '',
        inputClasses: 'form-control',
        dropdownOptions: {
          disabledDialCode: false,
        },
        inputOptions: {
          showDialCode: false,
        },
      },
    };
  },
  created() {
    this.template.category = 'MARKETING';
    this.$root.$on('call.crop', this.crop);
    this.getWhatsNumbers();
  },
  methods: {
    toggleAccordion(id) {
      const input = document.getElementById(`${id}-input`);
      // const checkboxInputs = document.querySelectorAll('.accordion .wpp-input-opt');
      this.$root.$emit('bv::toggle::collapse', id);
      if (input.checked === false) {
        /* [...checkboxInputs].forEach((checkboxInput) => {
          checkboxInput.checked = false;
        }); */
        document.getElementById(`${id}-input`).checked = true;
      } else {
        document.getElementById(`${id}-input`).checked = false;
      }
    },
    changeCategory(category) {
      /* if (category === 'UTILITY') {
        document.querySelectorAll('.carousel-inner .btn').forEach((el) => {
          el.innerHTML = `${this.$t('app.select')}`;
          el.classList.remove('btn-yup-purple-outline');
          el.classList.add('btn-yup-purple');
        });
        document
          .getElementById('btn_transactional')
          .classList.add('btn-yup-purple-outline');
        document.getElementById('btn_transactional').innerHTML = `${this.$tc(
          'app.selected', 1,
        )}`;
      } else if (category === 'MARKETING') {
        document.querySelectorAll('.carousel-inner .btn').forEach((el) => {
          el.innerHTML = `${this.$t('app.select')}`;
          el.classList.remove('btn-yup-purple-outline');
          el.classList.add('btn-yup-purple');
        });
        document
          .getElementById('btn_marketing')
          .classList.add('btn-yup-purple-outline');
        document.getElementById('btn_marketing').innerHTML = `${this.$tc(
          'app.selected', 1,
        )}`;
      } else {
        document.querySelectorAll('.carousel-inner .btn').forEach((el) => {
          el.innerHTML = `${this.$t('app.select')}`;
          el.classList.remove('btn-yup-purple-outline');
          el.classList.add('btn-yup-purple');
        });
        document
          .getElementById('btn_disposable_pass')
          .classList.add('btn-yup-purple-outline');
        document.getElementById('btn_disposable_pass').innerHTML = `${this.$tc(
          'app.selected', 1,
        )}`;
      } */
      this.template.structure.category = category;
      // this.template.structure.category = category;
      /* if (this.template.structure.category.length < 1) {
        this.template.structure.category = category;
      } else {
        this.template.structure.category = '';
      } */
    },
    deleteParam(e) {
      /* const regex = new RegExp('{{(.*?)}}', 'g');
      this.template.structure.body.example_parameters = this.template.structure.body.example_parameters.replace(regex, ''); */
      this.template.structure.body.example_parameters = [];
      this.template.structure.body.text = '';
      document.querySelectorAll('.param-col').forEach((el) => el.remove());
    },
    fixTitleChars() {
      const structure = this.template.structure;
      structure.name = structure.name
        .replace(/\s+/g, '_')
        .replace(/[^A-Za-z\d_]+/g, '')
        .toLowerCase();
    },

    chooseImage(maxWidth, maxHeight) {
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth,
        maxHeight,
        viewMode: 1,
        aspectRatio: 1.91,
        minSize: true,
        infoSize: '1080x566',
      });
    },
    crop(data) {
      this.$root.$emit('show.modal', 'request-modal');
      this.template.headerImage = data;
      console.log('crop:', this.template);
    },

    chooseFile() {
      setTimeout(() => {
        this.$refs.currentFile.click();
      }, 100);
    },

    getLocalFile(type) {
      console.log('getLocalFile:', this.$refs.currentFile);

      const input = this.$refs.currentFile;

      if (!input) return;

      const file = input.files[0];

      if (type === 'VIDEO') {
        this.template.headerVideo = {
          file,
          url: URL.createObjectURL(file),
        };
      } else if (type === 'DOCUMENT') {
        this.template.headerDocument = {
          file,
          url: URL.createObjectURL(file),
        };
      }
    },

    // Upload real
    upload(type) {
      const header = this.template.headers[type];
      const form = new FormData();

      let source;

      if (type === 'IMAGE') {
        source = this.template.headerImage;
      } else if (type === 'VIDEO') {
        source = this.template.headerVideo;
      } else {
        source = this.template.headerDocument;
      }

      form.append('files[]', source.file);
      form.append('role', 'public');
      form.append('folder', 'whatsapp');
      form.append('account_id', this.$store.state.account.id);

      if (type === 'IMAGE') {
        const crop = JSON.stringify([
          {
            x: source.x,
            y: source.y,
            w: source.width,
            h: source.height,
          },
        ]);

        form.append('crop', crop);
      }

      BucketService.upload(form, true, (progress) => {
        this.uploadProgress = progress;
      })
        .then((responses) => {
          console.log(responses);
          const url = responses.data[0].url;
          console.log('BucketService', url);
          header.example_parameter = url;
          this.sendRequest();
        })
        .catch((error) => {
          this.content = error;
          this.isSending = false;
          console.error(error);
        })
        .finally(() => {
          this.isSending = false;
        });
    },

    // Salvar template
    saveTemplate() {
      const category = this.template.structure.category;

      if (category !== 'AUTHENTICATION' && category !== 'MARKETING' && category !== 'UTILITY') {
        this.$toast.show({
          title: 'Erro',
          content: 'Selecione uma categoria de template',
          type: 'danger',
        });
        return;
      }

      this.isSending = true;

      console.log('Template to save');
      console.log(this.template);

      const type = this.template.headerType;

      if (type === 'TEXT' || !type) {
        this.sendRequest();
      } else {
        this.upload(type);
      }
    },

    getWhatsNumbers() {
      this.whatsNumbers = [];
      webApi.get('whatsapp/new/numbers').then(
        (response) => {
          console.log('WhatsNumbers', response.data);

          response.data.forEach((data) => {
            this.whatsNumbers.push(data);
          });

          const wId = this.whatsNumbers[0].phone_number;

          this.whatsapp_identification = wId;
          this.template.structure.business_id = wId;
        },
        (error) => {
          this.content = error;
          this.flowLoading = false;
        },
      );
    },
    changeNumber() {
      this.template.structure.business_id = this.whatsapp_identification;
    },
    callCrop(cropPreview, maxWidthP, maxHeightP, cropActive) {
      this.cropPreview = cropPreview;
      this.cropActive = cropActive;
      this.$root.$emit('show.modal', 'm-crop');
      this.$store.dispatch('crop/config', {
        maxWidth: maxWidthP,
        maxHeight: maxHeightP,
        viewMode: 1,
        aspectRatio: 1.91,
        minSize: true,
        infoSize: '1700x800',
      });
    },
    sendRequest() {
      // terminar aqui
      const template = this.template;
      const form = template.structure;
      const type = template.headerType;

      if (type) form.header = template.headers[type];

      if (template.footer.body) form.footer = template.footer;

      const buttonType = template.buttons.type;

      if (buttonType) {
        if (buttonType === 'CALL_TO_ACTION') {
          const buttons = { type: buttonType };

          if (template.call_to_action_dial_phone.number) {
            const dial = template.call_to_action_dial_phone;
            dial.number = dial.number.replace(/\D+/g, '');
            buttons.call_to_action_dial_phone =
              template.call_to_action_dial_phone;
          }

          if (template.call_to_action_website.url) {
            buttons.call_to_action_website = template.call_to_action_website;
          }

          form.buttons = buttons;
        } else if (buttonType === 'QUICK_REPLY') {
          form.buttons = {
            type: buttonType,
            quick_replies: template.quick_replies.filter((a) => a !== ''),
          };
        }
      }

      if (this.whatsapp_identification === '') {
        this.$toast.show({
          title: 'Erro',
          content: 'Você precisa escolher um número antes e enviar.',
          type: 'danger',
        });
        return;
      }

      WhatsAppService.createNewTemplate(form, this.whatsapp_identification)
        .then((response) => {
          this.$root.$emit('template.submit');
          console.log('WhatsAppService.createNewTemplate:', response);
          this.$toast.show({
            title: this.$t('generic-str.success'),
            content: this.$t('template-component.created'),
            type: 'success',
          });
          this.$router.go(-1);
          this.isSending = false;
        })
        .catch((ex) => {
          this.isSending = false;
          this.$toast.show({
            title: `${this.$t('sms.infos.filters.status.lbl-error')}`,
            content: 'Erro interno, por favor, tente novamente',
            type: 'danger',
          });
          console.log(ex.response.data.errors);
        })
        .finally(() => {
          this.isSending = false;
        });
    },
    updateHeaderParams() {
      const header = this.template.headers.TEXT;

      let last = 1;

      header.body = header.body.replace(/\{\{[^\s]+?\}\}/g, (param) => {
        let newValue = '';

        param = param.slice(2, -2);

        if (last === 1) newValue = '{{1}}';

        last += 1;

        // console.log('updateHeaderParams', newValue);

        return newValue;
      });

      // console.log('updateHeaderParams', header.body);
    },
    updateBodyParams() {
      const body = this.template.structure.body;

      let last = 1;
      let max = 0;

      // if (body.text === this.lastBody) return;

      this.lastBody = body.text.replace(/\{\{[^\s]+?\}\}/g, (param) => {
        let newValue;

        param = param.slice(2, -2);

        if (param <= last && param !== '0') {
          newValue = param;
        } else {
          last += 1;
          newValue = last;
        }

        // if (param < last || param === '0') {
        //   newValue = last;
        // } else {
        //   newValue = param;
        // }

        // last += 1;

        if (newValue > max) max = parseInt(newValue, 10);

        return `{{${newValue}}}`;
      });

      body.text = this.lastBody;

      if (max && body.example_parameters.length !== max) {
        body.example_parameters = new Array(max);
      }
    },
  },
};
</script>

<style scoped lang="scss">
/* .header-type {
  max-width: 240px;
} */

.content::v-deep {
  .vti__dropdown {
    background: #fff;
  }
}

.category {
  color: #0d0d0d;

  .col-2 {
    text-align: center;

    & > i {
      font-size: 24pt;
    }
  }

  .col-10 > h3,
  .col-2 > i {
    color: inherit !important;
  }
}

.preview {
  border-radius: 0.2rem;
  background-color: #e5ddd5;
  box-sizing: border-box;
  height: 100%;
  padding: 15px;
  position: relative;

  &::before {
    background: url('/assets/img/chat-bg.png');
    background-size: 366px 666px;
    content: '';
    opacity: 0.06;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
  }

  .preview-sample {
    max-width: 340px;
    position: sticky;
    top: 92px;
  }

  .preview-message,
  .preview-btns .btn {
    box-shadow: 0 1px 0.5px rgba(0, 0, 0, 0.15);
    background-color: #fff;
    border-radius: 0.32rem;
    width: 100%;
  }

  .preview-btns {
    margin: 0 !important;

    .btn {
      border: none;
      min-height: 32px;
      width: calc(50% - 0.25rem);
      margin: 0.25rem 0.25rem 0.5rem 0;

      &:nth-child(2) {
        margin: 0.25rem 0 0.5rem 0.25rem;
      }

      &.last {
        margin: 0.25rem 0 0.25rem 0;
        width: 100%;
      }
    }
  }

  .preview-message {
    border-top-left-radius: 0;
    padding: 0.25rem 0.25rem 0 0.25rem;
    position: relative;
    z-index: 2;
    min-height: 32px;

    & > header,
    & > div,
    & > footer {
      margin-bottom: 0.25rem;
      padding: 0.25rem;
    }

    & > time {
      position: absolute;
      bottom: 0.25rem;
      right: 0.5rem;
      font-size: 80%;
    }

    & > footer {
      color: rgb(140, 140, 140);
    }

    & > header {
      overflow: hiddden;
      max-height: 280px;
      max-height: 100%;
      font-weight: bold;

      .media {
        min-height: 100px;
        border-radius: 0.32rem;
        background: #ececec;
        margin-bottom: 0.2rem;
        overflow: hidden;

        & > img,
        & > object,
        & > video {
          object-fit: cover;
          width: 100%;
        }
      }
    }
  }
}
/* Rebranding */
.body-section {
  position: relative;
}

.input-info {
  position: absolute;
  right: 20px;
  top: unset;
  color: #aaa;
  bottom: -30px;
}

.input-info.text-area {
  top: 270px;
}

.new-template-whats {
  padding: 15px 15px 33px;
  margin: 0 auto;
  background: var(--background-2);
  margin: 10px;
}
.new-template-title {
  color: rgb(28, 30, 33);
}
.darkmode .new-template-title,
.darkmode .new-template-whats p span {
  color: #fff;
}
.new-template-whats p span {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: normal;
  overflow-wrap: normal;
  text-align: left;
  color: rgb(96, 103, 112);
}

.carousel-visible {
  position: sticky;
  top: 100px;
}

.carousel-template .carousel-indicators li {
  width: 15px;
  height: 15px;
  background-color: var(--clr-yup-purple);
  border-radius: 50%;
}

.carousel-template::v-deep {
  .carousel-item[role='listitem'] {
    margin-bottom: 1.2rem !important;

    & > img {
      display: none !important;
    }
  }

  .carousel-caption {
    position: static !important;

    & > p {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}

.carousel-template::v-deep .carousel-indicators li {
  width: 15px;
  height: 15px;
  background-color: var(--clr-yup-purple);
  border-radius: 50%;
}

.carousel-template::v-deep .carousel-control-next-icon,
.carousel-template::v-deep .carousel-control-prev-icon {
  width: 100%;
  height: 45px;
  position: relative;
}

.carousel-template::v-deep .carousel-control-next-icon::after,
.carousel-template::v-deep .carousel-control-prev-icon::after {
  color: var(--clr-yup-purple);
  font-weight: 400;
  font-family: 'Material Icons';
  right: 0;
  top: 10px;
  width: 100%;
}

.carousel-template::v-deep .carousel-control-next-icon::after {
  content: '\e5e1' !important;
}

.carousel-template::v-deep .carousel-control-prev-icon::after {
  content: '\e2ea';
}

.carousel-indicators {
  bottom: -1.5rem;
}

.icon-template span {
  color: var(--clr-yup-purple);
  font-size: 122px;
}

.new-template-whats {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.optional-template-input {
  display: block;
  float: left;
  font-size: 12px;
  background: #f4f4f4;
  padding: 5px;
  border-radius: 10px;
  transform: translateY(-8px) translateX(-5px) scale(0.8);
}

.darkmode .optional-template-input {
  background: var(--background-3);
}

.button-icon {
  height: fit-content;
  padding: 0;
}

.media-icon {
  height: fit-content;
  font-size: 1.75rem;
  transition: 0.5s;
  padding: 9px;
  cursor: pointer;
}

.media-icon.active {
  color: var(--clr-yup-purple);
}

.media-icon:hover {
  background-color: var(--background-icon-hover);
  border-radius: 5px;
}

::v-deep .body-section .textarea-information {
  display: none;
}

::v-deep body {
  background-color: #fff;
}

.create-template-section {
  background: transparent;
  box-shadow: unset;
}

/* Phone preview */
.preview-div {
  display: flex;
  position: sticky;
  /* align-items: center; */
  height: fit-content;
  top: 100px;
}

.preview-wrapper {
  width: 100%;
  // height: fit-content; // Position-sticky
  // height: 512px;
  z-index: 1;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.preview-btns .btn {
  color: var(--foreground);
}

.btn.last {
  width: 100%;
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }
  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    // margin-top: 10%; // Position-sticky
    z-index: -1;
  }
  .preview-wrapper {
    /* position: sticky;
    top: 100px; */
    display: flex;
    align-items: center;
    height: 512px;
    flex-wrap: wrap;
  }
  .card-preview {
    // position: absolute; // Position-sticky
    position: relative;
    width: 410px;
    // top: 200px; // Position-sticky
    // right: 20%; // Position-sticky
    right: 170px;
    margin-left: auto;
    margin-bottom: 1rem;
    min-height: 93px;
    --gap: 0.5rem;
  }
  .card-preview .card-body {
    padding: 1rem 1rem 2rem;
    display: flex;
    flex-direction: column;

    video {
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }

    img, object {
      margin-left: auto;
      margin-right: auto;
    }

    .img-loading {
      width: 378px;
      height: 189px;
    }
  }
}

.rebranding .card.card-preview .card-text.footer-item {
  color: var(--gray-font-color);
  margin-bottom: 0;
}

.card-preview:first-of-type {
  margin-top: calc(10 * var(--gap));
}

.card-preview:last-of-type {
  margin-bottom: calc(35 * var(--gap));
}

.card-preview:last-of-type .card-body {
  padding: 1rem;
}

.preview-btns {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.sort-group,
.create-group {
  width: auto;
}

.card-btn-action {
  background-color: var(--background-3);
}

/* btn Preview style */
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}
.preview-message-btns i {
  color: #00a5f4;
}
.preview-message-btns span {
  display: block;
  margin-bottom: 8px;
}

@media (min-width: 768px ) and (max-width: 1650px) {
  .card-preview {
    margin-right: auto;
    margin-left: -20px;
  }
}

/* Refact */
.left-content-column {
  > h2{
    font-size: 1rem;
    margin-bottom: 0;
    & ~ span{
      font-size: .75rem;
    }
  }
  .form-group {
    label {
      font-size: 1rem;
      > span {
        color: var(--gray-font-color-3);
      }
    }
    small {
      // margin-left: auto;
      text-align: right;
      color: var(--gray-font-color-3) !important;
    }
  }
}
.accordion-wpp-template {
  margin-top: .5rem;
  margin-bottom: 2rem;
  .card {
    background-color: #F9FAFB;
    border-color: #E5E5E5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    margin-bottom: 1.25rem;

    .card-header {
      display: flex;
      height: auto;
      align-items: center;
      padding: .5rem 1rem;
      background-color: #F2F4F7;
      border-color: #E5E5E5;
    }
  }
  .btn-wrapper-opt {
    width: 100%;
    display: flex;
    height: fit-content;
    align-items: center;
    gap: .5rem;
    position: relative;
    z-index: 1;
    span.material-symbols-outlined {
      font-size: 45px;
      color: #667085;
      margin-right: 1rem;
    }
    .btn-template {
      display: flex;
      height: auto;
      padding: 0;
      font-weight: 400;
      .wrapper-title {
        display: flex;
        flex-direction: column;
        text-align: start;
        p.title-opt {
          font-size: .875rem;
          margin-bottom: .25rem;
        }
        span {
          white-space: break-spaces;
          font-size: .75rem;
          color: #595959;
        }
      }
    }
    .toggle-check {
      margin-left: auto;
      position: relative;
      z-index: 0;
      .control .control_indicator {
        order: 1;
        margin-left: auto;
        margin-right: 0;
        margin-bottom: 0;
      }
    }
  }
}
.header-wrapper {
  width: 100%;
  display: flex;
  gap: 1.25rem;

  .card {
    // background-color: #F9FAFB;
    border-color: #E5E5E5;
    border-width: 1px !important;
    border-style: solid;
    box-shadow: none;
    width: 107px;
    height: 147px;
    /* &.card:not(:first-child) .card-header {
      padding: .85rem .5rem;
    } */
    .card-header {
      height: 100%;
      padding: .85rem .5rem;
      transition: .5s;
      box-shadow: 0px 0px 0px 0px #ffffff00;
      img {
        width: 100%;
        height: 61px;
        object-fit: none;
        object-position: 50% 0px;
      }
      &.active {
        box-shadow: 0px 0px 0px 1px var(--clr-yup-purple);
      }
    }
    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #F2F2F2;
      width: 100%;
      height: 61px;

      span.material-symbols-outlined {
        color: var(--gray-font-color);
      }
    }
    .btn-choose-type {
      height: fit-content;
      padding: 0;
      font-weight: 400;
      font-size: .75rem;
    }
  }
}
.body-example-param {
  display: flex;
  flex-direction: column;
  > .badge {
    width: fit-content;
    margin-bottom: .5rem;
    margin-left: auto;
  }
}

.btn-wrapper {
  display: flex;
  align-items: center;
}
/* Preview */
.preview-wrapper {
  position: sticky;
  top: 90px;
}
</style>
<style>
body {
  background-color: var(--background-3);
}

/* body.darkmode {
  background-color: #ffffff !important;
} */

</style>
